import { useState } from 'react';
import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide';
import MenuIcon from '@mui/icons-material/Menu';
import { slide as Menu } from 'react-burger-menu'
import './header.scss';

export default function Header() {

    const [hamburger, setHamburger] = useState(false);

    return (
        <>
        <Slide top>
            <header>
                    <div className="container">
                        <div className="brand">
                            <img src="images/logo.svg" />
                            <h1>Trident<span>Ventures</span></h1>
                        </div>
                        <div className="links">
                            <Link to="/">Home</Link>
                            <Link to="/about">About</Link>
                            <a href="https://www.behance.net/tridentventures">Projects</a>
                            <Link to="/#">Blog</Link>
                            <Link to="/contact" className='contact'>Contact us</Link>

                            <div className="mobile">
                                <MenuIcon sx={{fontSize: "50px"}} onClick={() => setHamburger(true)} />
                            </div>
                        </div>
                    </div>
            </header>
        </Slide>
        {/* Hamburger Menu for Mobile */}
        { hamburger && 
            <Menu
                right
                isOpen={hamburger}
                onClose={() => setHamburger(false)}
            >
                <Link onClick={() => setHamburger(false)} to="/">Home</Link>
                <Link onClick={() => setHamburger(false)} to="/about">About</Link>
                <Link onClick={() => setHamburger(false)} to="https://www.behance.net/tridentventures">Projects</Link>
                <Link onClick={() => setHamburger(false)} to="/#">Blog</Link>
                <Link onClick={() => setHamburger(false)} to="/contact">Contact us</Link>
            </Menu>
        }
        </>
    )
}