import Fade from 'react-reveal/Fade';
import './about.scss';

export default function About() {
    return (
        <div id="about">
            <section className="entry welcome">
                <Fade left>
                    <div className="text">
                        <p className='coming-soon'>ABOUT US</p>
                        <h2 className='title'>Trident<span>Ventures</span></h2>
                        <p className="services-explanation">
                            Proudly offering - Fullstack Development , UI UX & Graphic Design
                            Marketing, Steam based Trading Web & Trading BOTs services
                        </p>
                        <hr />
                        <p>
                            Welcome to our agency, where we specialize in providing a wide range of services to help our clients achieve their digital goals. From Fullstack development to UI UX Design & Graphic Design, Marketing and Steam Trading Websites & Trading BOT Services, we have the expertise to deliver exceptional results.
                            <br /><br />
                            Our team of experienced developers and designers are passionate about creating unique and innovative solutions that meet the specific needs of our clients.
                            <br /><br />
                            Whether you need a custom web application, a stunning website design, or a powerful trading bot, we have the skills and expertise to deliver. Our team takes a collaborative approach to every project, working closely with our clients to understand their goals and create tailored solutions that meet their needs.
                        </p>
                    </div>
                </Fade>
                <Fade right>
                    <div className="image">
                        <div className='layer-1'>
                            <div className='layer-2'>
                                <div className='layer-3'>
                                    <img src="images/logo-white.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </section>
            <section className="second">
                <Fade left>
                    <p>
                        At our agency, we believe that every project is an opportunity to create something truly remarkable. We take pride in delivering exceptional work that exceeds our clients' expectations, and we are always striving to improve and innovate. If you are looking for a reliable partner to help you achieve your digital goals, look no further than our agency. Contact us today to learn more about how we can help you succeed.
                    </p>
                </Fade>
            </section>
        </div>
    )
}