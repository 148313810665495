import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide';
import './footer.scss';

export default function Footer() {
    return (
        <Slide bottom>
            <footer>
                <div className="top-container">
                    <div>
                        <h4>Have any project in mind?</h4>
                        <p>We are available for full project development and support!</p>
                    </div>
                    <div className='buttons'>
                        <a
                            href="https://www.behance.net/tridentventures"
                            className='portfolio'
                            rel="noreferrer"
                            target='_blank'
                        >
                            Our Portfolio
                        </a>
                        <Link to="/contact" className='contact'>
                            <img src="images/email-icon.svg" />
                            Email Us
                        </Link>
                    </div>
                </div>
                <div className="main-container">
                    <h5>Get in Touch</h5>
                    <p className="slogan">For business and partnership inquiry please contact us  below!</p>
                    <div className="contacts">
                        <div className="row">
                            <div className="method">
                                <div>
                                    <img src="images/phone-icon.svg" />
                                </div>
                                <div>
                                    <p className="title">Whatsapp</p>
                                    <a
                                        href="https://wa.me/+387603089207"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="value"
                                    >
                                        wa.me/+387603089207
                                    </a>
                                </div>
                            </div>
                            <div className="method">
                                <div>
                                    <img src="images/phone-icon.svg" />
                                </div>
                                <div>
                                    <p className="title">Discord</p>
                                    <a
                                        href="https://discord.com/invite/QWzb2FrkWM"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="value"
                                    >
                                        discord.com/invite/QWzb2FrkWM
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="method">
                                <div>
                                    <img src="images/email-icon-2.svg" />
                                </div>
                                <div>
                                    <p className="title">Email</p>
                                    <p className="value">officialtridentventures@gmail.com</p>
                                </div>
                            </div>
                            <div className="method">
                                <div>
                                    <img src="images/email-icon-2.svg" />
                                </div>
                                <div>
                                    <p className="title">Slack</p>
                                    <a
                                        className="value"
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://join.slack.com/t/tridentventure/shared_invite/zt-2iodus6f8-j~J4HwgRaECQ0Fy3ogEDbA"
                                    >
                                        join.slack.com/t/tridentventure
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="method">
                                <div>
                                    <img src="images/map-icon.svg" />
                                </div>
                                <div>
                                    <p className="title">Location</p>
                                    <p className="value">Remote</p>
                                </div>
                            </div>
                            <div className="method">
                                <div>
                                    <img src="images/phone-icon.svg" />
                                </div>
                                <div>
                                    <p className="title">Telegram</p>
                                    <a
                                        href="https://t.me/TridentVenture"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="value"
                                    >
                                        t.me/TridentVenture
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className='bottom-text'>© Copyright Ⓒ TridentVentures.org  All Rights Reserved. Designed and Developed by Trident Ventures - www.tridentventures.org</p>
                </div>
                <img className="vector" src="images/footer-vector.svg" />
            </footer>
        </Slide>
    )
}