import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import Fade from 'react-reveal/Fade';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './home.scss';

export default function Home() {
    return (
        <div id="home">
            <section className="entry welcome">
                <Fade left>
                    <div className="text">
                        <p className='coming-soon'>FULL WEBSITE COMING SOON</p>
                        <h2 className='title'>Trident<span>Ventures</span></h2>
                        <p className="services-explanation">
                            Proudly offering - Fullstack Development , UI UX & Graphic Design,
                            Marketing, Steam based Trading Web & Trading BOTs services
                        </p>
                        <hr />
                        <p>
                            Welcome to our agency, where we specialize in providing a wide range of services to help our clients achieve their digital goals. From Fullstack development to UI UX Design & Graphic Design, Marketing and Steam Trading Websites & Trading BOT Services, we have the expertise to deliver exceptional results.
                            <br /><br />
                            Our team of experienced developers and designers are passionate about creating unique and innovative solutions that meet the specific needs of our clients.
                        </p>
                        <div className='buttons'>
                            <button onClick={() => window.open("https://www.behance.net/tridentventures", "_blank")}>
                                <img src="images/file-icon.svg" />
                                Download CV
                            </button>
                            <Link to="/about">More</Link>
                        </div>
                    </div>
                </Fade>
                <Fade right>
                    <div className="image">
                        <div className='layer-1'>
                            <div className='layer-2'>
                                <div className='layer-3'>
                                    <img src="images/logo-white.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </section>
            <section className="technologies">
                <span className="background-text">TECHNOLOGIES WE PROUDLY WORK WITH</span>
                <h3 className="main-text">TECHNOLOGIES WE PROUDLY WORK WITH</h3>
                <div className="tech-list">
                    <OwlCarousel
                        className='owl-theme' 
                        loop
                        dots={false}
                        autoplay={true}
                        autoplayTimeout={4000}
                        autoplayHoverPause={true}
                        stagePadding={0}
                        //margin={130} 
                        items={5}
                        center={false}
                        nav
                        navText={['<span class="arrow prev">&lt;</span>', '<span class="arrow next">&gt;</span>']}
                        responsive={{
                            1900: { items: 5 },
                            1200: { items: 4 },
                            570: { items: 3 },
                            0: { items: 2 },
                        }}
                    >
                        <div className='item'>
                            <div><img src="images/techs/react.png" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/figma.svg" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/aws.svg" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/nodejs.svg" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/python.png" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/php.png" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/javascript.png" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/firebase.png" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/mongodb.svg" alt="MongoDB" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/sass.png" alt="Sass" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/electron.svg" alt="Electron.js" /></div>
                        </div>
                        <div className='item'>
                            <div><img src="images/techs/p5js.svg" alt="p5js" /></div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
        </div>
    )
}