import { useState } from 'react';
import Fade from 'react-reveal/Fade';
import './contact.scss';

export default function Contact() {

    const [topics, setTopics] = useState("UI/UX design");
    const [senderEmail, setSenderEmail] = useState("");
    const [senderName, setSenderName] = useState("");
    const [senderMessage, setSenderMessage] = useState("");

    return (
        <div id="contact">
            <section className="entry welcome">
                <Fade left>
                    <div className="text">
                        <p className='coming-soon'>CONTACT US</p>
                        <h2 className='title'>Trident<span>Ventures</span></h2>
                        <p className="services-explanation">
                            Proudly offering - Fullstack Development , UI UX & Graphic Design
                            Marketing, Steam based Trading Web & Trading BOTs services
                        </p>
                        <hr />
                    </div>
                </Fade>
                <Fade right>
                    <div className="image">
                        <div className='layer-1'>
                            <div className='layer-2'>
                                <div className='layer-3'>
                                    <img src="images/logo-white.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </section>
            <section className="contact-form-container">
                <Fade>
                    <div className="contact-form">
                        <div className="info">
                            <h2>Let’s discuss on something cool together</h2>
                            <div className="contact-infos">
                                <div>
                                    <img src="images/email-icon-white.svg" alt="Email Icon" />
                                    <p>officialtridentventures@gmail.com</p>
                                </div>
                                <a
                                    className="primary"
                                    href="https://discord.com/invite/QWzb2FrkWM"
                                >
                                    <img src="images/phone-without-circle.svg" alt="Phone Icon" />
                                    <p>Discord - Trident Ventures</p>
                                </a>
                                <a href="https://t.me/TridentVenture">
                                    <img src="images/map-without-circle.svg" alt="Map Icon" />
                                    <p>Telegram - @TridentVentures</p>
                                </a>
                            </div>
                            <div className="social-medias">
                                <a href="https://www.facebook.com/TridentVenture/"><img src="images/facebook.svg" /></a>
                                <a href="https://www.instagram.com/tridentventures/"><img src="images/instagram-gold.svg" /></a>
                                <a href="https://twitter.com/tridentventure"><img src="images/twitter.svg" /></a>
                            </div>
                        </div>
                        <div className="form-window">
                            <div>
                                <p>I’m interested in...</p>
                                <div className="topics">
                                    <div 
                                        onClick={() => setTopics("UI/UX design")} 
                                        className={topics === "UI/UX design" ? "selected" : undefined}
                                    >
                                        UI/UX design
                                    </div>
                                    <div 
                                        onClick={() => setTopics("Fullstack Development")} 
                                        className={topics === "Fullstack Development" ? "selected" : undefined}
                                    >
                                        Fullstack Development
                                    </div>
                                    <div 
                                        onClick={() => setTopics("Games Trading Websites & Trading BOT Services")} 
                                        className={topics === "Games Trading Websites & Trading BOT Services" ? "selected" : undefined}
                                    >
                                        Games Trading Websites & Trading BOT Services
                                    </div>
                                    <div 
                                        onClick={() => setTopics("Web design")} 
                                        className={topics === "Web design" ? "selected" : undefined}
                                    >
                                        Web design
                                    </div>
                                    <div 
                                        onClick={() => setTopics("Graphic design")} 
                                        className={topics === "Graphic design" ? "selected" : undefined}
                                    >
                                        Graphic design
                                    </div>
                                    <div 
                                        onClick={() => setTopics("Other")} 
                                        className={topics === "Other" ? "selected" : undefined}
                                    >
                                        Other
                                    </div>
                                </div>
                                <input placeholder="Your name" onChange={e => setSenderName(e.target.value)} />
                                <input placeholder="Your email" onChange={e => setSenderEmail(e.target.value)} type="email" />
                                <input placeholder="Your message" onChange={e => setSenderMessage(e.target.value)} />
                                <a target="_blank" href={`mailto:officialtridentventures@gmail.com?subject=I%20request%20${topics}%20services&body=Name:%20${senderName}%0D%0AEmail:%20${senderEmail}%0D%0AMessage:%20${senderMessage}`}>
                                    <img src="images/send-message.svg" />
                                    Send Message
                                </a>
                            </div>
                        </div>
                    </div>
                </Fade>
            </section>
            <section className="ending">
                <p>
                    At our agency, we believe that every project is an opportunity to create something truly remarkable. We take pride in delivering exceptional work that exceeds our clients' expectations, and we are always striving to improve and innovate. If you are looking for a reliable partner to help you achieve your digital goals, look no further than our agency. Contact us today to learn more about how we can help you succeed.
                </p>
                <button onClick={() => window.open("https://www.behance.net/tridentventures", "_blank")}>
                    <img src="images/file-icon.svg" />
                    Download CV
                </button>
            </section>
        </div>
    )
}